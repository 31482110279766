import React, { createContext } from "react";
import { useLocalStore } from "mobx-react-lite";
import PropTypes from "prop-types";
import {getInitialDataForAddressInfo, getInitialDataForHomeDetail, getInitialDataForInsuranceType} from "../utils/pageData/HomeQuotePageDataLoader";
import {evaluateFlag, evaluateFlagTreatment, evaluateFlagConfig, isEnabled} from "../utils/featureflags/FeatureFlaggingUtils";

export const HomeQuoteStoreContext = createContext();
export const HomeQuoteStoreProvider = ({children}) => {
    const defaultTransactionData = {region : "US", quoteDeclined: false, quoteAssistance: false, declineType: undefined, declineReasons: [], quoteSuccess: false};
    const store = useLocalStore(() => ({

        insuranceDetails: undefined,
        homeDetails: undefined,
        transactionData: defaultTransactionData,
        transactionInProgress: false, 
        region: "",
        mediaAlphaToken: undefined,
        offset: 1,
        featureFlags: [],

        saveInsuranceTypeDetails(details){
            if(details){
                store.insuranceDetails = details;
            }
        },
        saveHomeInfoDetails(details){
            if(details){
                store.homeDetails = details;
            }
        },
        saveAddressInfoDetails(details){
            if(details){
                store.addressInfo = details;
            }
        },
        get addressInfoDetails() {
            let addressInfo;

            if (store.addressInfo) {
                addressInfo = store.addressInfo;
            }
            if (addressInfo) {
                return addressInfo;
            }

            return getInitialDataForAddressInfo(store.region);
        },
        get homeInfoDetails() {
            let homeDetails;

            if (store.homeDetails) {
                homeDetails = store.homeDetails;
            }
            if (homeDetails) {
                return homeDetails;
            }
            return getInitialDataForHomeDetail();
        },
        get insuranceTypeDetails() {
            let insuranceDetails;

            if (store.insuranceDetails) {
                insuranceDetails = store.insuranceDetails;
            }
            if (insuranceDetails) {
                return insuranceDetails;
            }
            return getInitialDataForInsuranceType();
        },
        saveRegion(region){
            store.region = region;
        },
        saveOffset(offset){
            store.offset = offset;
        },
        saveTransactionData(transactionData){
            store.transactionData = transactionData;
        },
        saveTransactionInProgress(transactionInProgress){
            store.transactionInProgress = transactionInProgress
        },
        saveMediaAlphaToken(mediaAlphaToken){
            store.mediaAlphaToken = mediaAlphaToken;
        },
        saveFeatureFlags(featureFlags){
            store.featureFlags = featureFlags;
        },
        resetStore(){
            store.insuranceDetails=undefined;
            store.homeDetails= undefined;
            store.transactionData= defaultTransactionData;
            store.transactionInProgress= false;
        },
        resetStoreExceptionTransactionData(){
            store.insuranceDetails=undefined;
            store.homeDetails= undefined;
            store.transactionInProgress= false;
        },
        
        isFeatureEnabled(featureName) {
            return isEnabled(store.featureFlags[featureName]);
        },
        evaluateFeatureIfEnabled(featureName) {
            return evaluateFlag(store.featureFlags[featureName]);
        },
        evaluateFeatureTreatmentIfEnabled(featureName) {
            return evaluateFlagTreatment(store.featureFlags[featureName]);
        },
        evaluateFeatureConfigIfEnabled(featureName) {
            return evaluateFlagConfig(store.featureFlags[featureName]);
        }
    }));

    HomeQuoteStoreProvider.propTypes = {
        children:PropTypes.any.isRequired,
    };

    return (
        <HomeQuoteStoreContext.Provider value={store}>{children}</HomeQuoteStoreContext.Provider>
    );
};