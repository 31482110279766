import TransportService from "../transport/TransportService";
import {getVinURL} from "../../configuration/environmentConfig";

const generateMakeOptions = (jsonResponse) => {
    const options = [];
    options.push({key: "Select One", value: ""});
    jsonResponse.forEach(modelItem => {
        options.push({key: modelItem.key, value: modelItem.value});
    });

    return options;
}

const generateModelOptions = (jsonResponse) => {
    const options = [];
    options.push({key: "Select One", value: ""});
    jsonResponse.forEach(modelItem => {
        options.push({key: modelItem.Model, value: modelItem.Model});
    });

    return options;
}

const generateBodyTypeOptions = (jsonResponse) => {
    const options = [];
    if (jsonResponse.length !== 1) {
        options.push({key: "Select One", value: ""});
    }
    jsonResponse.forEach(bodyTypeItem => {
        options.push({key: bodyTypeItem.fullBodyType, value: bodyTypeItem.bodyType});
    });

    return options;
}

const generateEngineTypeOptions = (jsonResponse, region, isRAPAV2Effective) => {
    const options = [];
    jsonResponse = jsonResponse.filter((arr, index, self) =>
        index === self.findIndex((t) => ((isRAPAV2Effective)? t.fullCylinders === arr.fullCylinders && t.fullEngineType === arr.fullEngineType && t.engineSize === arr.engineSize:
        t.fullCylinders === arr.fullCylinders && t.fullEngineType === arr.fullEngineType)));
    if (jsonResponse.length !== 1) {
        options.push({key: "Select One", value: ""});
    }
    jsonResponse.forEach(engineTypeItem => {
        const {cylinders, fullCylinders, engineType, fullEngineType, engineSize} = engineTypeItem;
        let keyString = fullEngineType !== null ? `${fullCylinders} - ${fullEngineType}` : fullCylinders;
        let valueString = `${cylinders}`;

        if (isRAPAV2Effective) {
            keyString = (engineSize !== null && engineSize !== "NULL") ? `${keyString} - ${engineSize}` : `${fullCylinders} - ${fullEngineType}`;
            valueString = `${cylinders}-${engineType}:${engineSize}`;
        }

        options.push({key: keyString, value: valueString});
    });

    return options;
}

const generateIsAWDOptions = (jsonResponse) => {
    const options = [];
    if (jsonResponse.length === 0) {
        options.push({key: "NO", value: ""});
    }
    jsonResponse.forEach(isAWDItem => {
        const isAWD = isAWDItem.fourWheelDrive === "4" ? "YES" : "NO";

        options.push({key: isAWD, value: isAWDItem.fourWheelDrive === "4"});
    });

    return options;
}

const getMakeOptions = () => {
    return [
        { value: "", key: "Select One"
        },
        { value: "ACUR", key: "ACURA"
        },
        { value: "ALFA", key: "ALFA ROMEO"
        },
        { value: "AMGL", key: "AM GENERAL"
        },
        { value: "AMC", key: "AMERICAN MOTORS"
        },
        { value: "ARO", key: "ARO"
        },
        { value: "ASTN", key: "ASTON MARTIN"
        },
        { value: "AUDI", key: "AUDI"
        },
        { value: "AUST", key: "AUSTIN"
        },
        { value: "AVTI", key: "AVANTI"
        },
        { value: "BERT", key: "BERTONE"
        },
        { value: "BMW", key: "BMW"
        },
        { value: "BUIK", key: "BUICK"
        },
        { value: "CADI", key: "CADILLAC"
        },
        { value: "CPRI", key: "CAPRI"
        },
        { value: "CHEC", key: "CHECKER"
        },
        { value: "CHEV", key: "CHEVROLET"
        },
        { value: "CHRY", key: "CHRYSLER"
        },
        { value: "COLT", key: "COLT"
        },
        { value: "DAEW", key: "DAEWOO"
        },
        { value: "DAIH", key: "DAIHATSU"
        },
        { value: "DELO", key: "DELOREAN"
        },
        { value: "DODG", key: "DODGE"
        },
        { value: "EAGL", key: "EAGLE"
        },
        { value: "FERR", key: "FERRARI"
        },
        { value: "FIAT", key: "FIAT"
        },
        { value: "FORD", key: "FORD"
        },
        { value: "GENS", key: "GENESIS"
        },
        { value: "GMC", key: "GMC"
        },
        { value: "HOND", key: "HONDA"
        },
        { value: "HUMM", key: "HUMMER"
        },
        { value: "HYUN", key: "HYUNDAI"
        },
        { value: "IMPL", key: "IMPERIAL"
        },
        { value: "INFI", key: "INFINITI"
        },
        { value: "INTL", key: "INTERNATIONAL"
        },
        { value: "ISZU", key: "ISUZU"
        },
        { value: "JAG", key: "JAGUAR"
        },
        { value: "JEEP", key: "JEEP"
        },
        { value: "KIA", key: "KIA"
        },
        { value: "LANC", key: "LANCIA"
        },
        { value: "ROVR", key: "LAND ROVER"
        },
        { value: "LEXS", key: "LEXUS"
        },
        { value: "LINC", key: "LINCOLN"
        },
        { value: "LOTS", key: "LOTUS"
        },
        { value: "MASS", key: "MASERATI"
        },
        { value: "MAZD", key: "MAZDA"
        },
        { value: "MBNZ", key: "MERCEDES BENZ"
        },
        { value: "MERC", key: "MERCURY"
        },
        { value: "MG", key: "MG"
        },
        { value: "MINI", key: "MINI"
        },
        { value: "MITS", key: "MITSUBISHI"
        },
        { value: "MOBV", key: "MOBILITY VENTURES"
        },
        { value: "NSSN", key: "NISSAN"
        },
        { value: "OLDS", key: "OLDSMOBILE"
        },
        { value: "OPEL", key: "OPEL"
        },
        { value: "PEUG", key: "PEUGEOT"
        },
        { value: "PINI", key: "PININFARINA"
        },
        { value: "PLYM", key: "PLYMOUTH"
        },
        { value: "POLS", key: "POLESTAR"
        },
        { value: "PONT", key: "PONTIAC"
        },
        { value: "PORS", key: "PORSCHE"
        },
        { value: "RAM", key: "RAM"
        },
        { value: "RIVI", key: "RIVIAN"
        },
        { value: "RNLT", key: "RENAULT"
        },
        { value: "SAAB", key: "SAAB"
        },
        { value: "SATN", key: "SATURN"
        },
        { value: "SCIO", key: "SCION"
        },
        { value: "SMRT", key: "SMART"
        },
        { value: "STER", key: "STERLING"
        },
        { value: "SUBA", key: "SUBARU"
        },
        { value: "SUZU", key: "SUZUKI"
        },
        { value: "TSLA", key: "TESLA"
        },
        { value: "TYTA", key: "TOYOTA"
        },
        { value: "TRIU", key: "TRIUMPH"
        },
        { value: "TVR", key: "TVR"
        },
        { value: "VLKS", key: "VOLKSWAGEN"
        },
        { value: "VLVO", key: "VOLVO"
        },
        { value: "VPG", key: "VPG"
        },
        { value: "YUGO", key: "YUGO"
        }
    ];
}

export default {
    getYearOptions: () => {
        const currentYear = new Date().getFullYear();
        const options = [];
        options.push({key: "Select One", value: ""});
        for (let i = currentYear+1; i>=currentYear-39; i-=1) {
            options.push({key: i, value: i});
        }
        return options;
    },
    getMakeOptions: () => {
        return getMakeOptions();
    },
    getMakeOptionsTemp: (year) => {
        return new Promise((resolve, reject) => {
            try {
                if (year !== undefined) {
                    resolve(getMakeOptions());
                } else {
                    resolve(generateMakeOptions([]));
                }
            }
            catch (error) {
                reject(error);
            }
        });
    },
    getMakeOptionsWithYear: (year) => {
        return new Promise((resolve, reject) => {
            if (year !== undefined) {
                const url = `vehicleMakeTypes?year=${year}`;
                const headers = {'Content-Type': 'application/json'};
                TransportService.send(getVinURL() + url, headers, "get", null).then((result) => {
                    resolve(generateMakeOptions(result.data));
                }).catch((error) => {
                    reject(error);
                });
            } else {
                resolve(generateMakeOptions([]));
            }
        });
    },
    getModelOptions: (year, make) => {
        return new Promise((resolve, reject) => {
            if (year !== undefined && make !== undefined) {
                const url = `vehicleModelv2?year=${year}&make=${make}`;
                const headers = {'Content-Type': 'application/json'};
                TransportService.send(getVinURL() + url, headers, "get", null).then((result) => {
                    resolve(generateModelOptions(result.data));
                }).catch((error) => {
                    reject(error);
                });
            } else {
                resolve(generateModelOptions([]));
            } 
          });
    },
    getBodytypeOptions: (year, make, model, jurisdiction) => {
        return new Promise((resolve, reject) => {
            if (year !== undefined && make !== undefined && model !== undefined && jurisdiction !== undefined) {
                const url = `vehicleBodyType?year=${year}&make=${make}&model=${model}&jurisdiction=${jurisdiction}`;
                const headers = {'Content-Type': 'application/json'};
                TransportService.send(getVinURL() + url, headers, "get", null).then((result) => {
                    resolve(generateBodyTypeOptions(result.data));
                }).catch((error) => {
                    reject(error);
                });
            } else {
                resolve(generateBodyTypeOptions([]));
            }
        });
    },
    getEnginetypeOptions: (year, make, model, jurisdiction, bodytype, region, isRAPAV2Effective) => {
        return new Promise((resolve, reject) => {
            if (year !== undefined && make !== undefined && model !== undefined && jurisdiction !== undefined && bodytype !== undefined) {
                let url = `vehicleEngineType?year=${year}&make=${make}&model=${model}&jurisdiction=${jurisdiction}&bodytype=${bodytype}`;
                if (isRAPAV2Effective) {
                    url += `&rapaversion=v2`;
                }
                const headers = {'Content-Type': 'application/json'};
                TransportService.send(getVinURL() + url, headers, "get", null).then((result) => {
                    resolve(generateEngineTypeOptions(result.data, region, isRAPAV2Effective));
                }).catch((error) => {
                    reject(error);
                });
            } else {
                resolve(generateEngineTypeOptions([]));
            } 
        });
    },
    getIsAWDOptions: (year, make, model, bodytype, engineType, jurisdiction, region, isRAPAV2Effective) => {
        return new Promise((resolve, reject) => {
            if (year !== undefined && make !== undefined && model !== undefined && bodytype !== undefined && jurisdiction !== undefined && engineType !== undefined) {
                let url = `vehicleIs4WD?year=${year}&make=${make}&model=${model}&bodytype=${bodytype}&jurisdiction=${jurisdiction}`;
                if(typeof engineType === 'string' && engineType.includes("-")) {
                    const [cylinders, rest] = engineType.split("-");
                    const [engineTypeIndicator, enginesize] = rest?.split(":");
                    url+= `&cylinders=${cylinders}&enginesize=${enginesize}`;
                }
                if (isRAPAV2Effective) {
                    url += `&rapaversion=v2`;
                }
                const headers = {'Content-Type': 'application/json'};
                TransportService.send(getVinURL() + url, headers, "get", null).then((result) => {
                     resolve(generateIsAWDOptions(result.data));
                 }).catch((error) => {
                     reject(error);
                 });
            } else {
                resolve(generateIsAWDOptions([]));
            } 
        });
    }
};