import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, {lazy, Suspense} from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Route} from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import {getSplitIOConfig} from "./app/js/configuration/environmentConfig";
import useRegion from "./app/js/customhooks/UseRegion";
import { SplitFactory } from "@splitsoftware/splitio-react";
import {AutoQuoteStoreProvider} from "./app/js/statemanagement/AutoQuoteDataStore";
import {HomeQuoteStoreProvider} from "./app/js/statemanagement/HomeQuoteDataStore";
import {urlLOB} from "./app/js/utils/constant/constants"

(async () => {
    const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
    const AutoMainComponent = lazy(() => import("./app/js/components/auto/pages/MainComponent"));
    const HomeMainComponent = lazy(() => import("./app/js/components/home/pages/MainComponent"));
    const region = useRegion();

    ReactDOM.render(
        <>
        <BrowserRouter basename="/">
            <Route path={`/${urlLOB.AUTO}/${region}`}>
                <AutoQuoteStoreProvider>
                    <SplitFactory config={getSplitIOConfig()}>
                        {isIE11 &&
                        <div id="root" style={{borderImage: "none", fontFamily: "Arial, Helvetica, sans-seriff", padding: "0 20px 30px 20px", width: "600px", margin: "0 auto"}}>
                            <p style={{borderImage: "none", textAlign: "center", fontFamily: "Arial, Helvetica, sans-seriff", marginTop: "40px", fontSize: "24px", marginBottom: "50px"}}>
                                <strong>We're sorry</strong><br/>Your browser is not supported
                            </p>
                            <p style={{textAlign: "center"}}>We recommend using the latest version of one of these browsers:</p>
                            <ul style={{marginLeft: "45px"}}>
                                <li>Chrome</li>
                                <li>Firefox</li>
                                <li>Microsoft Edge</li>
                                <li>Safari</li>
                            </ul>
                        </div>}
                        {!isIE11 &&<Suspense fallback={<div>Loading...</div>}><AutoMainComponent /></Suspense>}
                    </SplitFactory>
                </AutoQuoteStoreProvider>
            </Route>
            <Route path={`/${urlLOB.PERSONAL_PROPERTY}/${region}`}>
                <HomeQuoteStoreProvider>
                    <SplitFactory config={getSplitIOConfig()}>
                        {isIE11 &&
                        <div id="root" style={{borderImage: "none", fontFamily: "Arial, Helvetica, sans-seriff", padding: "0 20px 30px 20px", width: "600px", margin: "0 auto"}}>
                            <p style={{borderImage: "none", textAlign: "center", fontFamily: "Arial, Helvetica, sans-seriff", marginTop: "40px", fontSize: "24px", marginBottom: "50px"}}>
                                <strong>We're sorry</strong><br/>Your browser is not supported
                            </p>
                            <p style={{textAlign: "center"}}>We recommend using the latest version of one of these browsers:</p>
                            <ul style={{marginLeft: "45px"}}>
                                <li>Chrome</li>
                                <li>Firefox</li>
                                <li>Microsoft Edge</li>
                                <li>Safari</li>
                            </ul>
                        </div>}
                        {!isIE11 &&<Suspense fallback={<div>Loading...</div>}><HomeMainComponent /></Suspense>}
                    </SplitFactory>
                </HomeQuoteStoreProvider>
            </Route>
        </BrowserRouter>
        </>
       ,
        document.getElementById('root')
    );
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();