import axios from 'axios';


export default {
    send: (endpoint, headers, method, params) => {
        const options = {
            url: endpoint,
            method,
            data: params,
            headers
        };
        return axios(options);
    },
    get: (endpoint, headers, params) => {
        return axios.get(endpoint, {
            params,
            data: params,
            headers
        });
    }
};